import '@/styles/globals.css';
import '@/styles/extentions.css';
import 'bulma-tooltip/dist/css/bulma-tooltip.min.css';
import App, { AppContext, AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import Head from 'next/head';
import '@fortawesome/fontawesome-free/css/all.css';

type MyAppProps = AppProps & {
  baseUrl: string
}

export default function MyApp({ Component, pageProps: { session, ...pageProps }, baseUrl }: MyAppProps) {
    if(typeof window !== 'undefined') {
        if(baseUrl !== undefined && baseUrl !== window.location.origin ) {
            alert(`This app has moved to a new location (${baseUrl}), we will redirect you there`);
            window.location.href = baseUrl;
        }
    }

    return (
        <SessionProvider session={session}>
            <Head>
                <title>AIP Management tool</title>
            </Head>
            <Component {...pageProps} />
        </SessionProvider>

    );
}

MyApp.getInitialProps = async (context: AppContext) => {
    const appProps: any = await App.getInitialProps(context);
    return { ...appProps, baseUrl: process.env.BASE_URL };
};